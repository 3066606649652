<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <div class="demo-spin-article">
                <ts-panel>
                    <form-wizard
                        :start-index.sync="activeIndex"
                        color="#00acac"
                        :title="''"
                        :subtitle="''"
                        finishButtonText="Finish"
                        errorColor="red"
                        ref="wizard"
                        @on-complete="onComplete"
                    >
                        <wizard-step
                            slot-scope="props"
                            slot="step"
                            :tab="props.tab"
                            :transition="props.transition"
                            :index="props.index"
                        >
                            <div
                                slot="active-step"
                                style="display: flex; justify-content:center; align-items: center;"
                            >
                                <lottie
                                    v-if="props.index == 2"
                                    :options="defaultOptions"
                                    :height="65"
                                    :width="65"
                                />
                                <lottie
                                    v-else
                                    :options="defaultOptions2"
                                    :height="65"
                                    :width="65"
                                />
                            </div>
                        </wizard-step>
                        <tab-content
                            style="min-height: 460px !important;"
                            :title="
                                $t('customerLoanRequest.loanRequestInformation')
                            "
                            icon="fas fa-file-invoice-dollar"
                        >
                            <show />
                        </tab-content>
                        <tab-content
                            style="min-height: 460px !important;"
                            :title="$t('customerLoanRequest.loanApproval')"
                            icon="fas fa-hand-holding-usd"
                            :before-change="onApprove"
                        >
                            <loan-approval
                                v-model="model"
                                :validation="errors"
                                ref="loanApproval"
                            />
                        </tab-content>
                        <tab-content
                            title="Done"
                            icon=" "
                            style="min-height: 460px !important;"
                        >
                            <lottie
                                v-if="activeIndex == 1"
                                :options="defaultOptions"
                                :height="200"
                                :width="200"
                            />
                            <show />
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-left">
                                <wizard-button
                                    v-if="props.activeTabIndex == 0"
                                    @click.native="
                                        $router.push({
                                            name: 'customer-loan-request'
                                        })
                                    "
                                    :style="'background:#e2e2e2'"
                                    >{{ $t("cancel") }}</wizard-button
                                >
                            </div>
                            <div class="wizard-footer-left">
                                <wizard-button
                                    v-if="
                                        props.activeTabIndex > 0 &&
                                            !props.isLastStep
                                    "
                                    @click.native="props.prevTab()"
                                    :style="'background:#e2e2e2'"
                                    >{{
                                        $t("customerLoanRequest.previous")
                                    }}</wizard-button
                                >
                            </div>
                            <div class="wizard-footer-right">
                                <wizard-button
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab()"
                                    class="wizard-footer-right"
                                    :style="props.fillButtonStyle"
                                    :disabled="nextStep"
                                >
                                    <i
                                        class="fas fa-circle-notch fa-spin mr-2"
                                        v-if="nextStep"
                                    ></i>
                                    {{
                                        props.activeTabIndex == 1
                                            ? $t("customerLoanRequest.approve")
                                            : $t("customerLoanRequest.next")
                                    }}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    @click.native="onComplete"
                                    class="wizard-footer-right finish-button"
                                    :style="props.fillButtonStyle"
                                    :disabled="nextStep"
                                >
                                    <i
                                        class="fa fa-spinner spin mr-2"
                                        v-if="nextStep"
                                    ></i>
                                    {{
                                        props.isLastStep
                                            ? $t("customerLoanRequest.done")
                                            : $t("customerLoanRequest.next")
                                    }}
                                </wizard-button>
                                <ts-button
                                    v-if="props.isLastStep"
                                    color="warning"
                                    outline
                                    class="tw-mr-2"
                                    @click="customerContract()"
                                    ><i class="far fa-file-pdf"></i>
                                    {{
                                        $t("customerLoanRequest.loanAgreement")
                                    }}</ts-button
                                >
                            </div>
                        </template>
                    </form-wizard>
                </ts-panel>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <contract-pdf-preview
            v-model="contract_pdf_view"
            :request-id="request_id"
            @cancel="value => (contract_pdf_view = value)"
        />
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Lottie from "vue-lottie";
import animationData from "@/assets/animation.js";
import animationData2 from "@/assets/animation2.js";
import {
    FormWizard,
    WizardStep,
    TabContent,
    WizardButton
} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { Errors } from "form-backend-validation";
import moment from "moment";
import Show from "./components/show.vue";
import LoanApproval from "./components/loan-approval.vue";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";
import ContractPdfPreview from "./loan-disbursement/components/contract-pdf-preview";

export default {
    name: "loanApprovalCreate",
    components: {
        Lottie,
        FormWizard,
        TabContent,
        WizardStep,
        WizardButton,
        Show,
        LoanApproval,
        ContractPdfPreview
    },
    data() {
        return {
            activeIndex: 0,
            defaultOptions: { animationData: animationData, loop: false },
            defaultOptions2: { animationData: animationData2, loop: false },
            animationSpeed: 1,
            errors: new Errors(),
            loading: false,
            nextStep: false,
            continueNextStep: false,
            clear: false,
            contract_pdf_view: false,
            request_id: null,
            model: {
                request_id: null,
                customer_id: null,
                requested_date: moment().format("DD-MM-YYYY"),
                loan_type_id: null,
                currency_id: null,
                loan_type_cur_id: null,
                group_id: null,
                is_new_cycle: null,
                cycle_number: null,
                request_amount: null,
                request_amount_in_word: null,
                request_loan_duration: null,
                request_installment_type_id: null,
                request_interest_rate: null,
                source_of_income_1: null,
                average_imcome_1: null,
                expense_1: null,
                source_of_income_2: null,
                average_imcome_2: null,
                expense_2: null,
                source_of_income_3: null,
                average_imcome_3: null,
                expense_3: null,
                source_of_income_4: null,
                average_imcome_4: null,
                expense_4: null,
                total_monthly_income: null,
                total_monthly_expense: null,
                mfi_owe_due_1: null,
                mfi_owe_due_1_installment: null,
                mfi_owe_due_1_outstanding: null,
                mfi_owe_due_2: null,
                mfi_owe_due_2_installment: null,
                mfi_owe_due_2_outstanding: null,
                mfi_owe_due_3: null,
                mfi_owe_due_3_installment: null,
                mfi_owe_due_3_outstanding: null,
                total_owe_due_to_be_paid: null,
                total_outstanding: null,
                disbursal_amount: null,
                first_installment_amount: null,
                app_amount: null,
                app_loan_duration: null,
                app_installment_type_id: null,
                app_interest_rate: null,
                interval_1_week: null,
                interval_2_week: null,
                interval_1_month: null,
                admin_fee: null,
                cbc_fee: null,
                monthly_collection_fee: null,
                commune_leader_name: null,
                commune_leader_phone: null,
                witness_name: null,
                witness_phone: null,
                other_name: null,
                other_phone: null,
                emp_co_id: null,
                emp_bm_id: null,
                approve_status: null,
                current_app_level: null,
                approval_comment: null,
                attached_request_form: null,
                attached_contact: null,
                purpose_id: null,
                payment_interval: null,
                app_disbursement_date: moment().format("DD-MM-YYYY"),
                app_first_installment_date: moment()
                    .add(1, "months")
                    .format("DD-MM-YYYY")
            }
        };
    },
    computed: {
        ...mapState("creditOperation/customerLoanRequest", ["edit_data"]),
        isUpdate() {
            if (!isEmpty(this.edit_data)) {
                return true;
            }
            return false;
        }
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("creditOperation/customerLoanRequest", [
            "getLoanType",
            "getCurrency",
            "getPurpose",
            "getRepaymentMethod",
            "find"
        ]),
        validateStep(name) {
            var refToValidate = this.$refs[name];
            return refToValidate.validateForm();
        },
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.find(this.$route.params.id)
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.setEditData();
                    this.$refs.loanApproval.calculateAdminFee();
                });
            this.setCurrencyToDefault();
            this.loading = false;
        },
        setCurrencyToDefault() {
            this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id;
            this.model.cbc_fee = this.$store.state.authUser.sys_setting[0]
                .cbc_fee
                ? parseFloat(this.$store.state.authUser.sys_setting[0].cbc_fee)
                : null;
        },
        onComplete() {
            this.$refs.wizard.reset();
            this.clearInput();
            this.$router.push({ name: "customer-loan-request" });
        },
        async onApprove() {
            var isValid = this.$refs.loanApproval.validateForm();
            if (!isValid) return;
            this.nextStep = true;
            this.continueNextStep = false;
            this.errors = new Errors();
            this.waiting = true;
            this.$store.commit(
                "creditOperation/loanDisbursement/SET_EDIT_DATA",
                this.model
            );
            await this.$store
                .dispatch("creditOperation/customerLoanRequest/approve", {
                    id: this.model.request_id,
                    data: this.model
                })
                .then(async response => {
                    this.clear = true;
                    this.waiting = false;
                    this.nextStep = true;
                    this.continueNextStep = true;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                    this.$store.commit(
                        "creditOperation/customerLoanRequest/SET_EDIT_DATA",
                        response.resource
                    );
                })
                .catch(error => {
                    this.waiting = false;
                    this.errors = new Errors(error.errors);
                    this.nextStep = false;
                    this.continueNextStep = false;
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                });
            this.nextStep = false;
            return this.continueNextStep;
        },
        clearInput() {
            this.model.request_id = null;
            this.model.customer_id = null;
            this.model.requested_date = moment().format("DD-MM-YYYY");
            this.model.loan_type_id = null;
            this.model.currency_id = null;
            this.model.loan_type_cur_id = null;
            this.model.group_id = null;
            this.model.is_new_cycle = null;
            this.model.cycle_number = null;
            this.model.request_amount = null;
            this.model.request_amount_in_word = null;
            this.model.request_loan_duration = null;
            this.model.request_installment_type_id = null;
            this.model.request_interest_rate = null;
            this.model.source_of_income_1 = null;
            this.model.average_imcome_1 = null;
            this.model.expense_1 = null;
            this.model.source_of_income_2 = null;
            this.model.average_imcome_2 = null;
            this.model.expense_2 = null;
            this.model.source_of_income_3 = null;
            this.model.average_imcome_3 = null;
            this.model.expense_3 = null;
            this.model.source_of_income_4 = null;
            this.model.average_imcome_4 = null;
            this.model.expense_4 = null;
            this.model.total_monthly_income = null;
            this.model.total_monthly_expense = null;
            this.model.mfi_owe_due_1 = null;
            this.model.mfi_owe_due_1_installment = null;
            this.model.mfi_owe_due_1_outstanding = null;
            this.model.mfi_owe_due_2 = null;
            this.model.mfi_owe_due_2_installment = null;
            this.model.mfi_owe_due_2_outstanding = null;
            this.model.mfi_owe_due_3 = null;
            this.model.mfi_owe_due_3_installment = null;
            this.model.mfi_owe_due_3_outstanding = null;
            this.model.total_owe_due_to_be_paid = null;
            this.model.total_outstanding = null;
            this.model.disbursal_amount = null;
            this.model.first_installment_amount = null;
            this.model.app_amount = null;
            this.model.app_loan_duration = null;
            this.model.app_installment_type_id = null;
            this.model.app_interest_rate = null;
            this.model.interval_1_week = null;
            this.model.interval_2_week = null;
            this.model.interval_1_month = null;
            this.model.admin_fee = null;
            this.model.cbc_fee = null;
            this.model.monthly_collection_fee = null;
            this.model.commune_leader_name = null;
            this.model.commune_leader_phone = null;
            this.model.witness_name = null;
            this.model.witness_phone = null;
            this.model.other_name = null;
            this.model.other_phone = null;
            this.model.emp_co_id = null;
            this.model.emp_bm_id = null;
            this.model.approve_status = null;
            this.model.current_app_level = null;
            this.model.approval_comment = null;
            this.model.attached_request_form = null;
            this.model.attached_contact = null;
            this.model.purpose_id = null;
            this.model.payment_interval = null;
            this.model.app_disbursement_date = moment().format("DD-MM-YYYY");
            this.model.app_first_installment_date = moment()
                .add(1, "months")
                .format("DD-MM-YYYY");
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.request_id = this.edit_data.request_id;
                this.model.customer_id = this.edit_data.customer_id;
                this.model.requested_date = this.edit_data.requested_date;
                this.model.loan_type_id = this.edit_data.loan_type_id;
                this.model.currency_id = this.edit_data.currency_id;
                this.model.loan_type_cur_id = this.edit_data.loan_type_cur_id;
                this.model.group_id = this.edit_data.group_id;
                this.model.is_new_cycle = this.edit_data.is_new_cycle;
                this.model.cycle_number = this.edit_data.cycle_number;
                this.model.request_amount = this.edit_data.request_amount;
                this.model.request_amount_in_word = this.edit_data.request_amount_in_word;
                this.model.request_loan_duration = this.edit_data.request_loan_duration;
                this.model.request_installment_type_id = this.edit_data.request_installment_type_id;
                this.model.request_interest_rate = this.edit_data.request_interest_rate;
                this.model.source_of_income_1 = this.edit_data.source_of_income_1;
                this.model.average_imcome_1 = this.edit_data.average_imcome_1;
                this.model.expense_1 = this.edit_data.expense_1;
                this.model.source_of_income_2 = this.edit_data.source_of_income_2;
                this.model.average_imcome_2 = this.edit_data.average_imcome_2;
                this.model.expense_2 = this.edit_data.expense_2;
                this.model.source_of_income_3 = this.edit_data.source_of_income_3;
                this.model.average_imcome_3 = this.edit_data.average_imcome_3;
                this.model.expense_3 = this.edit_data.expense_3;
                this.model.source_of_income_4 = this.edit_data.source_of_income_4;
                this.model.average_imcome_4 = this.edit_data.average_imcome_4;
                this.model.expense_4 = this.edit_data.expense_4;
                this.model.total_monthly_income = this.edit_data.total_monthly_income;
                this.model.total_monthly_expense = this.edit_data.total_monthly_expense;
                this.model.mfi_owe_due_1 = this.edit_data.mfi_owe_due_1;
                this.model.mfi_owe_due_1_installment = this.edit_data.mfi_owe_due_1_installment;
                this.model.mfi_owe_due_1_outstanding = this.edit_data.mfi_owe_due_1_outstanding;
                this.model.mfi_owe_due_2 = this.edit_data.mfi_owe_due_2;
                this.model.mfi_owe_due_2_installment = this.edit_data.mfi_owe_due_2_installment;
                this.model.mfi_owe_due_2_outstanding = this.edit_data.mfi_owe_due_2_outstanding;
                this.model.mfi_owe_due_3 = this.edit_data.mfi_owe_due_3;
                this.model.mfi_owe_due_3_installment = this.edit_data.mfi_owe_due_3_installment;
                this.model.mfi_owe_due_3_outstanding = this.edit_data.mfi_owe_due_3_outstanding;
                this.model.total_owe_due_to_be_paid = this.edit_data.total_owe_due_to_be_paid;
                this.model.total_outstanding = this.edit_data.total_outstanding;
                this.model.disbursal_amount = this.edit_data.disbursal_amount;
                this.model.first_installment_amount = this.edit_data.first_installment_amount;
                this.model.app_amount = this.edit_data.app_amount
                    ? this.edit_data.app_amount
                    : this.edit_data.request_amount;
                this.model.app_loan_duration = this.edit_data.app_loan_duration
                    ? this.edit_data.app_loan_duration
                    : this.edit_data.request_loan_duration;
                this.model.app_installment_type_id = this.edit_data
                    .app_installment_type_id
                    ? this.edit_data.app_installment_type_id
                    : this.edit_data.request_installment_type_id;
                this.model.app_interest_rate = this.edit_data.app_interest_rate
                    ? this.edit_data.app_interest_rate
                    : this.edit_data.request_interest_rate;
                this.model.interval_1_week = this.edit_data.interval_1_week;
                this.model.interval_2_week = this.edit_data.interval_2_week;
                this.model.interval_1_month = this.edit_data.interval_1_month;
                this.model.admin_fee = this.edit_data.admin_fee;
                this.model.cbc_fee = this.edit_data.cbc_fee;
                this.model.monthly_collection_fee = this.edit_data.monthly_collection_fee;
                this.model.commune_leader_name = this.edit_data.commune_leader_name;
                this.model.commune_leader_phone = this.edit_data.commune_leader_phone;
                this.model.witness_name = this.edit_data.witness_name;
                this.model.witness_phone = this.edit_data.witness_phone;
                this.model.other_name = this.edit_data.other_name;
                this.model.other_phone = this.edit_data.other_phone;
                this.model.emp_co_id = this.edit_data.emp_co_id;
                this.model.emp_bm_id = this.edit_data.emp_bm_id;
                this.model.approve_status = this.edit_data.approve_status;
                this.model.current_app_level = this.edit_data.current_app_level;
                this.model.approval_comment = this.edit_data.approval_comment;
                this.model.attached_request_form = this.edit_data.attached_request_form;
                this.model.attached_contact = this.edit_data.attached_contact;
                this.model.purpose_id = this.edit_data.purpose_id;
                this.model.app_disbursement_date = this.edit_data
                    .app_disbursement_date
                    ? this.edit_data.app_disbursement_date
                    : moment().format("DD-MM-YYYY");
                this.model.app_first_installment_date = this.edit_data
                    .app_first_installment_date
                    ? this.edit_data.app_first_installment_date
                    : moment()
                          .add(1, "months")
                          .format("DD-MM-YYYY");
            }
        },
        customerContract() {
            this.contract_pdf_view = true;
            this.request_id = [this.$route.params.id]
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN APPROVAL",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        this.$store.commit("creditOperation/customerLoanRequest/RESET_STATE");
        this.$store.commit(
            "creditOperation/customerLoanRequest/CLEAR_RESOURCES"
        );
        next();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: #00acac;
}
.wizard-header {
    padding: 5px !important;
    position: relative;
    border-radius: 3px 3px 0 0;
    text-align: center;
}
</style>
